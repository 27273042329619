@charset "utf-8";

@import "app/mixin";
@import "app/variables";
@import "app/scrollbar";

// *,
// *:before,
// *:after {
// 	box-sizing: border-box;
// }
// html,body {
// 	height: 100%;
// }
// body {
// 	margin: 0;
// 	padding: 0;
// 	font-family: $font-family-sans-serif;
// 	font-size: 14px;

// 	@media (min-width: $md-min) {
// 		overflow: hidden;
// 	}
// }

.notfound {
  padding: 80px 0;

  &-body {
    text-align: center;
    .title {
      margin: 0 0 40px;
      font-size: 28px;
      letter-spacing: 4px;
    }

    .text {
      margin: 0 0 40px;
      line-height: 2;
      letter-spacing: 1px;
    }
  }
}