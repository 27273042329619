/**
 * SuperGradient
 * SASS MULTI-COLOR GRADIENT MIXIN (LINEAR & RADIAL)
 * Options
 * $type: linear*, radial
 * $from: top*, left, top left, top right, bottom right, bottom left, center (radial)
 * $colors: hex, rgba
 * *default
 *
 * Include: bower install super-gradient
 *
 * Demo: http://super-gradient.ib.gs/
 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #fff; }

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2); }

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2); }

.notfound {
  padding: 80px 0; }
  .notfound-body {
    text-align: center; }
    .notfound-body .title {
      margin: 0 0 40px;
      font-size: 28px;
      letter-spacing: 4px; }
    .notfound-body .text {
      margin: 0 0 40px;
      line-height: 2;
      letter-spacing: 1px; }
